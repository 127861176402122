import React from 'react';

import notice from '../../assets/images/notice.jpg';

import './styles.css';

const Notices = (props) => {
    return (
      <div className="box-notice">
        <img src={notice} alt="Notícia" />
        <div className="bar-color" style={{backgroundColor: props.color}}></div>
        <h3>Lorem ipsum</h3>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    );
  }

export default Notices;