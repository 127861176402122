import React from 'react';

import './styles.css';

const Services = (props) => {
    return (
      <div className="boxServices">
        {props.image}
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        </p>
      </div>
    );
  }

export default Services;