import React, { useState } from 'react';

import logo from '../../assets/images/logo.png';

import './styles.css';
export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      visible: true,
    };
  }

  render() {
    return (
      <>
      <div id="menu" className={this.state.visible?'fadeIn':'fadeOut'}>
        <div id="menu-logo">
          <img src={logo} alt="Logo" />
        </div>
          <ul>
              <li><button className="btn-menu">Lorem Ipsum</button></li>
              <li><a href="#">Lorem Ipsum</a></li>
              <li><a href="#">Lorem Ipsum</a></li>
              <li><a href="#">Lorem Ipsum</a></li>
          </ul>
      </div>
      </>
    );
  }
}  