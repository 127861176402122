import React from 'react';

import './styles.css';

import logo from '../../assets/images/logo.png';

import Menu from '../Menu';

import { withRouter } from 'react-router-dom';

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      menu: false,
      term: ''
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    let header = document.querySelector('header');
    let hasFixed = header.classList.contains('fixed');

    if(window.scrollY > window.innerHeight){
      if(!hasFixed){
        header.classList.add('fixed');
      }
    } else {
      if(hasFixed){
        header.classList.remove('fixed');
      }
    }
  }

  handleShowMenu = () => {
      let menu = this.state.menu ? false : true;
      this.setState({ menu });
      let b = document.querySelector('body');
      let c = document.querySelector('.div-flex ul');
      let d = document.querySelector('.div-flex');
      let e = document.querySelector('#toggleMenu');
      if(menu){
        b.style.overflow = 'hidden';
        c.style.display = 'none';
        d.style.width = '73%';
        e.style.position = 'absolute';
        e.style.right = '15px';
      } else {
        b.style.overflow = 'auto';
        c.style.display = 'flex';
        d.style.width = 'initial';
        e.style.position = 'initial';
      }
    }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  }
  

  render() {
    return (
      <>
        <header className="dark">
          <a href="/">
            <img src={logo} alt="Pim - ESG" id="logo"/>
          </a>
          <div className="div-flex">
            <ul>
              <li>
                <button className="green">Lorem Ipsum</button>
              </li>
              <li>
                <a href="#">Lorem Ipsum</a>
              </li>
              <li>
                <a href="#">Lorem Ipsum</a>
              </li>
              <li>
                <a href="#">Lorem Ipsum</a>
              </li>
            </ul>
            <button id="toggleMenu" onClick={() => this.handleShowMenu()}>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
            </button>
          </div>
        </header>
        {this.state.menu &&
            <Menu />        
        }
      </>
    );
  }
}

export default withRouter(Header);