import React, { useRef, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import banner from '../../assets/images/banner2.png';

import book from '../../assets/images/book.png';
import trees from '../../assets/images/trees.png';
import molecula from '../../assets/images/molecula.png';
import heart from '../../assets/images/heart.png';

import t1 from '../../assets/images/t1.jpg';
import t2 from '../../assets/images/t2.jpg';
import t3 from '../../assets/images/t3.jpg';
import t4 from '../../assets/images/t4.jpg';

import Services from '../../components/Services';
import Team from '../../components/Team';
import Notice from '../../components/Notices';

import { FaLinkedinIn } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

import './styles.css';

const Home = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(4);
  const [provCurrentSlide, setProvCurrentSlide] = useState(null);

  useEffect(() => {
    getSlides();
  }, []);

  useEffect(()=>{
    let bannerInterval = setInterval(() => {
      let min = 0;
      let max = 8;
      let toSet = Math.floor(Math.random() * (max - min)) + min;
      setCurrentSlide(toSet);
    }, 2500);

    return ()=> {
      clearInterval(bannerInterval);
    };
  });

  const getSlides = () => {
    const toSlides = [
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home site',
        color: "rgb(70 154 163 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/1181248/pexels-photo-1181248.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home loja',
        color: "rgb(32 80 52 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/1181396/pexels-photo-1181396.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home site',
        color: "rgb(116 199 53 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home loja',
        color: "rgb(255 158 23 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/3182759/pexels-photo-3182759.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home site',
        color: "rgb(138 197 63 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/1181611/pexels-photo-1181611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home loja',
        color: "rgb(138 197 63 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home site',
        color: "rgb(138 197 63 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/2312369/pexels-photo-2312369.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home site',
        color: "rgb(70 154 163 / 85%)"
      },
      {
        title: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas varius cursus.',
        url: 'https://images.pexels.com/photos/3183198/pexels-photo-3183198.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        alt: 'Home loja',
        color: "rgb(32 80 52 / 85%)"
      },
    ];

    setSlides(toSlides);
  }

  const slideHover = index => {
    setProvCurrentSlide(index);
  }

  const slideOut = index => {
    setProvCurrentSlide(null);
  }

  return (
    <>
    <section className="banner-home">
      <div id="social-medias">
        <a href="#"><FaLinkedinIn /></a>
        <a href="#"><FaFacebookF /></a>
        <a href="#"><FaTwitter /></a>
        <a href="#"><FaInstagram /></a>
      </div>
      <div id="banner-home">
        {slides.map((slide, index) => (
          <div 
            className={`banner-box ${(index === provCurrentSlide) || (index === currentSlide && provCurrentSlide === null) ? 'active' : ''}`}
            style={{ backgroundColor: slide.color }}
            onMouseOver={() => slideHover(index)}
            onMouseOut={() => slideOut(index)}>
            <div className="banner-box-content">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              {/* <a href="#">Continue lendo <IoIosArrowDroprightCircle /></a> */}
            </div>
          </div>
        ))}
        {slides.map((slide, index) => (
          <img src={slide.url} alt={slide.alt} className={(index === provCurrentSlide) || (index === currentSlide && provCurrentSlide === null) ? 'active' : ''} />
        ))}
      </div>
    </section>
    <section className="section-services">
      <div className="container-fluid">
        <h1 className="title" style={{color: "#205034"}}>Serviços</h1>
        <div className="section-box-services">
          <Services
          image={
            <img src={book} alt="Icon" />
          } />
          <Services
          image={
            <img src={trees} alt="Icon" />
          } />
          <Services
          image={
            <img src={molecula} alt="Icon" />
          } />
          <Services
          image={
            <img src={heart} alt="Icon" />
          } />
        </div>
      </div>
    </section>
    <section className="section-team">
      <div className="container-fluid">
        <h1 className="title" style={{color: "#ff9e17"}}>Time</h1>
        <Row>
          <Col sm={3} xs={6}>
            <Team 
            image={
              <img src={t1} alt="Time" />
            } />
          </Col>
          <Col sm={3} xs={6}>
            <Team 
            style={'#205034'}
            image={
              <img src={t2} alt="Time" />
            } />
          </Col>
          <Col sm={3} xs={6}>
            <Team
            style={'#74c735'}
            image={
              <img src={t3} alt="Time" />
            } />
          </Col>
          <Col sm={3} xs={6}>
            <Team 
            style={'#ff9e17'}
            image={
              <img src={t4} alt="Time" />
            } />
          </Col>
        </Row>
      </div>
    </section>
    <section className="section-notice">
      <div className="container-fluid">
        <h1 className="title" style={{color: "#70c5c6"}}>Notícias</h1>
        <Row>
          <Col sm={4} xs={6}>
            <Notice
            color={'#70c5c6'} />
          </Col>
          <Col sm={4} xs={6}>
            <Notice
            color={'#f69098'} />
          </Col>
          <Col sm={4} xs={6}>
            <Notice
            color={'#ff9e17'} />
          </Col>
        </Row>
      </div>
    </section>
    </>
  );
}

export default Home;