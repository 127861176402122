import React from 'react';

import './styles.css';

const Team = (props) => {
    return (
      <>
      <div className="box-team">
        {props.image}
        <div className="box-name" style={{backgroundColor: props.style}}>
          <p>
            Lorem ipsum
          </p>
        </div>
      </div>
      </>
    );
  }

export default Team;